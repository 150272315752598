import {PointTooltipProps, ResponsiveLine} from '@nivo/line';
import {Serie} from "../types/serie";
import React from "react";

type ChartLineProps = {
    data: Serie[];
    isFetching: boolean;
};

const CustomTooltip: React.FC<PointTooltipProps> = (point) => {
    return (
        <div className="chart-tooltip">
            <div>
                <strong>Effekt {point.point.data.yFormatted} W</strong>
            </div>
            <div>
                <strong>Tidspunkt {point.point.data.xFormatted}</strong>
            </div>
        </div>
    );
};

const LineChart: React.FC<ChartLineProps> = ({data, isFetching}) => {
    const labelsToSkip = Math.floor(data[0].data.length / 7);
    return (
        <div className={`chart-container ${isFetching && 'skeleton'}`}>
            <ResponsiveLine
                data={data}
                theme={{
                    axis: {
                        legend: {
                            text: {
                                fill: '#eee',
                            },
                        },
                    },
                    grid: {
                        line: {
                            stroke: 'rgb(255, 255 ,255, 0.2)',
                        },
                    },
                }}
                colors={["#61cdbb"]}
                tooltip={CustomTooltip}
                margin={{top: 30, right: 55, bottom: 50, left: 15}}
                xScale={{type: 'point'}}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: true,
                    reverse: false
                }}
                yFormat=" >-.2f"
                curve="catmullRom"
                axisTop={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: data[0].data.map((v, i) => i % labelsToSkip !== 0 ? '' : v.x),
                    legend: 'Tidspunkt',
                    legendOffset: 36,
                    legendPosition: 'middle',
                    renderTick: tick => {
                        return (
                            <text
                                x={tick.x}
                                y={tick.y + 20}
                                textAnchor="middle"
                                alignmentBaseline="middle"
                                style={{
                                    fill: '#eee',
                                    fontSize: 10,
                                }}>
                                {tick.value}
                            </text>
                        );
                    }

                }}
                axisLeft={null}
                axisRight={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Effekt i watt',
                    legendOffset: 45,
                    legendPosition: 'middle',
                    renderTick: tick => {
                        return (
                            <text
                                x={tick.x + 18}
                                y={tick.y + 2}
                                textAnchor="middle"
                                alignmentBaseline="middle"
                                style={{
                                    fill: '#eee',
                                    fontSize: 10,
                                }}>
                                {tick.value}
                            </text>
                        );
                    }
                }}
                enableGridX={false}
                enablePoints={false}
                enableArea={true}
                pointSize={7}
                pointColor={{theme: 'labels.text.fill'}}
                pointBorderWidth={1}
                pointBorderColor={{from: 'serieColor'}}
                pointLabelYOffset={-12}
                useMesh={true}
            />
        </div>
    );
}

export default LineChart;
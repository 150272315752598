import {BarDatum, ResponsiveBar} from '@nivo/bar'
import React from "react";
import {BarTooltipProps} from "@nivo/bar/dist/types/types";
import {getNorwegianMonth} from "../helpers/month";

type ChartBarProps = {
    data: BarDatum[];
    isFetching: boolean;
};


function formatDate(date: Date): String {
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
    let year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

const CustomTooltip: React.FC<BarTooltipProps<any>> = (point) => {
    return (
        <div className="chart-tooltip">
            <div>
                <strong>{point.value} kW/t</strong>
            </div>
            <div>
                <strong>{formatDate(new Date(point.data.time))}</strong>
            </div>
        </div>
    );
};

function tickSkipInterval(numberOfTicks: number) {
    let largeScreen = window.innerWidth > 800;
    if (!largeScreen && numberOfTicks > 15) {
        return 2;
    }
    return 1;
}

const BarChart: React.FC<ChartBarProps> = ({data, isFetching}) => {
    const ticksToSkip = tickSkipInterval(data.length);
    return (
        <div className={`chart-container ${isFetching && 'skeleton'}`}>
            <ResponsiveBar
                data={data}
                keys={[
                    'production',
                ]}
                indexBy="time"
                theme={{
                    axis: {
                        legend: {text: {fill: '#eee'}},
                        ticks: {text: {fill: '#eee'}}
                    },
                    grid: {
                        line: {
                            stroke: 'rgb(255, 255 ,255, 0.2)',
                        },
                    },
                }}
                margin={{top: 30, right: 50, bottom: 50, left: 10}}
                padding={0.3}
                groupMode="grouped"
                valueScale={{type: 'linear'}}
                indexScale={{type: 'band', round: true}}
                colors={["#5ec7b8"]}
                tooltip={CustomTooltip}
                borderRadius={3}
                borderColor={{theme: 'background'}}
                borderWidth={10}
                axisTop={null}
                axisLeft={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend:
                        getNorwegianMonth(parseInt(data[0].time.toString().split('-')[1])),
                    legendPosition: 'middle',
                    legendOffset: 35,
                    format: d => parseInt(d.split('-')[2]),
                    tickValues: data.map((d, i) => i % ticksToSkip === 0 ? d.time : null).filter(Boolean),
                }}
                axisRight={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'kW/t',
                    legendPosition: 'middle',
                    legendOffset: 40,
                }}
                labelSkipWidth={15}
                labelSkipHeight={12}
                labelTextColor="#000"

                role="application"
                ariaLabel="Produksjon"
            />
        </div>)
}

export default BarChart;
import React, {useState} from 'react';
import {QueryClient, QueryClientProvider, useQuery} from 'react-query';
import './App.css'
import LineChart from "./components/LineChart";
import BarChart from "./components/BarChart";
import {Serie} from "./types/serie"
import dayjs from "dayjs";
import AngleLeftSVG from './components/icons/angle-left.svg';
import AngleRightSVG from './components/icons/angle-right.svg';
import HourGlassSVG from './components/icons/hour-glass.svg';
import SolarHouseSVG from './components/icons/solar-house.svg';
import SpinnerSVG from './components/icons/spinner.svg'
import ChargingStationSVG from './components/icons/charging-station.svg'
import ElectricBikeSVG from './components/icons/electric-bike.svg'
import {BarDatum} from "@nivo/bar";
import {getNorwegianMonth, formatToMonthString} from "./helpers/month";
import formatToIsoString from "./helpers/date";

const queryClient = new QueryClient();

interface PacData {
    time: string
    pac: number
}

interface DayData {
    date: string
    eacToday: number
    eacTotal: number
    pac: PacData[]
}

interface MonthData {
    totalProduction: number,
    production: BarDatum[]
}

interface InverterData {
    day?: DayData
    month?: MonthData
}

function getQueryParameters(newUrl: string) {
    const [, search] = newUrl.split('?')
    const params = search ? search.split('&') : [];
    const queryParams: Record<string, string> = {};

    params.forEach((param) => {
        const [key, value] = param.split('=');
        queryParams[key] = value;
    });

    return queryParams;
}

export default function App() {
    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <Charts/>
            </QueryClientProvider>
        </div>
    );
}

function Charts() {
    const [queryParams, setQueryParams] = useState(getQueryParameters(window.location.search));
    const { isFetching, isLoading, error, data } = useQuery<InverterData, Error>(
        ['repoData', queryParams],
        () =>
            fetch(`https://9se31ovv78.execute-api.eu-central-1.amazonaws.com/query?${new URLSearchParams(queryParams)}`).then(
                (res) => res.json()
            ),
        {
            enabled: !!queryParams,
        }
    );

    const changeTypeClick = (type: string, e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        const newUrl = `${window.location.pathname}?type=${type}`
        window.history.pushState({}, '', newUrl);
        setQueryParams(getQueryParameters(newUrl));
    };

    if (isLoading) return <div className="loading"><img src={SpinnerSVG} alt="Laster data"/></div>;
    else if (error) return <div>En feil har oppstått: {error.message}</div>;
    else if (!data) return <div>Ingen data tilgjengelig</div>;

    return (
        <div className="main-container">
            {data.day && <Day
                data={data.day}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                changeTypeClick={changeTypeClick}
                isFetching={isFetching}/>}
            {data.month && <Month data={data.month}
                                  setQueryParams={setQueryParams}
                                  changeTypeClick={changeTypeClick}
                                  isFetching={isFetching}
            />}
        </div>
    );
}

function toDaySeries(data: DayData): Serie[] {
    return [
        {
            id: data.date,
            data: data.pac.map(({time, pac}) => ({
                x: dayjs(time.split('.')[0] + 'Z').format("HH:mm"),
                y: pac,
            }))
        }
    ]
}

interface DayProps {
    data: DayData;
    queryParams: Record<string, string>,
    setQueryParams: (v: Record<string, string>) => void,
    changeTypeClick: (type: string, e: React.MouseEvent<HTMLAnchorElement>) => void;
    isFetching: boolean;
}

const Day: React.FC<DayProps> = ({data, queryParams, setQueryParams, changeTypeClick, isFetching}) => {
    const currentParams = new URLSearchParams(queryParams);
    let date = currentParams.get('date');

    const minusDayClick = () => {
        changeDays(-1)
    };

    const addDayClick = () => {
        changeDays(1)
    };

    const changeDays = (days: number) => {
        let date = currentParams.get('date');
        if (!date) {
            date = formatToIsoString(new Date());
        }
        let newDate = new Date(date)
        newDate.setDate(newDate.getDate() + days)
        const newUrl = `${window.location.pathname}?date=${formatToIsoString(newDate)}`;
        setQueryParams(getQueryParameters(newUrl));
        window.history.pushState({}, '', newUrl);
    }

    return (
        <>
            <div className="header-navigation">
                {date !== '2023-08-01' &&
                    <img src={AngleLeftSVG} alt="Forrige dato" className="pointer hover-grow"
                         onClick={!isFetching ? minusDayClick : undefined}/>}
                <h1>{data.date}</h1>
                {date && date !== formatToIsoString(new Date()) &&
                    <img src={AngleRightSVG} alt="Neste dato" className="pointer hover-grow"
                         onClick={!isFetching ? addDayClick : undefined}/>
                }
            </div>
            <div className="keynumber-container">
                <div className={`keynumber-cell ${isFetching && 'skeleton'}`}>
                    <div>
                        <span>{data.eacToday} kWt</span>
                        <img src={SolarHouseSVG} alt="Dagens produksjon"/>
                    </div>
                    <span className="keynumber-cell-subtext">Produksjon på dato</span>
                </div>
                <div className={`keynumber-cell ${isFetching && 'skeleton'}`}>
                    <div>
                        <span>{data.eacTotal} kWt</span>
                        <img src={HourGlassSVG} alt="Totalproduksjon for anlegget"/>
                    </div>
                    <span className="keynumber-cell-subtext">Totalproduksjon for anlegget</span>
                </div>
            </div>
            <LineChart data={toDaySeries(data)} isFetching={isFetching}/>
            <div style={{padding: '20px'}}><a href="/?type=MONTH"
                                              onClick={(e) => !isFetching ? changeTypeClick('MONTH', e) : undefined}
                                              className="buttonLink">Gå til
                måned</a></div>
        </>
    )
}

interface MonthProps {
    data: MonthData;
    setQueryParams: (v: Record<string, string>) => void,
    changeTypeClick: (type: string, e: React.MouseEvent<HTMLAnchorElement>) => void;
    isFetching: boolean;
}

const Month: React.FC<MonthProps> = ({data, setQueryParams, changeTypeClick, isFetching}) => {
    const currentParams = new URLSearchParams(window.location.search);
    let date = currentParams.get('date');

    const addMonthClick = () => {
        changeMonths(1)
    };

    const minusMonthClick = () => {
        changeMonths(-1)
    };

    const changeMonths = (months: number) => {
        const currentParams = new URLSearchParams(window.location.search);
        let date = currentParams.get('date');
        if (!date) {
            date = formatToMonthString(new Date());
        }
        let newDate = new Date(`${date}-01`)
        newDate.setMonth(newDate.getMonth() + months)
        const newUrl = `${window.location.pathname}?date=${formatToMonthString(newDate)}&type=MONTH`;
        setQueryParams(getQueryParameters(newUrl));
        window.history.pushState({}, '', newUrl);
    };

    return (
        <>
            {<div className="header-navigation">
                {date !== '2023-05' &&
                    <img src={AngleLeftSVG} alt="Forrige måned" className="pointer hover-grow"
                         onClick={!isFetching ? minusMonthClick : undefined}/>}
                <h1>{getNorwegianMonth(parseInt(data.production[0].time.toString().split('-')[1]))}</h1>
                {date && date !== formatToMonthString(new Date()) &&
                    <img src={AngleRightSVG} alt="Neste måned" className="pointer hover-grow"
                         onClick={!isFetching ? addMonthClick : undefined}/>}
            </div>}
            <div className="keynumber-container">
                <div className={`keynumber-cell ${isFetching && 'skeleton'}`}>
                    <div>
                        <span>{data.totalProduction.toFixed(1)} kWt</span>
                        <img src={SolarHouseSVG} alt="Denne måneden"/>
                    </div>
                    <span className="keynumber-cell-subtext">Denne måneden</span>
                </div>
                <div className={`keynumber-cell ${isFetching && 'skeleton'}`}>
                    <div>
                        <span>{(data.totalProduction / 0.5).toFixed(1)}</span>
                        <img src={ElectricBikeSVG} alt="Fulladet sykkel"/>
                    </div>
                    <span className="keynumber-cell-subtext">Ladede elsykler</span>
                </div>
                <div className={`keynumber-cell ${isFetching && 'skeleton'}`}>
                    <div>
                        <span>{(data.totalProduction / 70).toFixed(1)}</span>
                        <img src={ChargingStationSVG} alt="Fulladet Tesla model Y"/>
                    </div>
                    <span className="keynumber-cell-subtext">Ladede Tesla Model Y</span>
                </div>
            </div>
            <BarChart data={data.production} isFetching={isFetching}/>
            <div style={{padding: '20px'}}><a href="/?type=DAY" onClick={(e) => changeTypeClick('DAY', e)}
                                              className="buttonLink">Gå til dag</a></div>
        </>)
}